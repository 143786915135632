/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCvaySwJeAMc7bUUENg0AHLVaVQqhJL5Dg",
  "appId": "1:595792687133:web:158ba291200b2de40196f4",
  "authDomain": "schooldog-i-houston-ga.firebaseapp.com",
  "measurementId": "G-RNS1EWJJS1",
  "messagingSenderId": "595792687133",
  "project": "schooldog-i-houston-ga",
  "projectId": "schooldog-i-houston-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-houston-ga.appspot.com"
}
